import {
    Modal,
    initTE
  } from "tw-elements";
  
initTE({ Modal });

const modal = document.getElementById('newsletter') as HTMLElement;

const uploadModal = new Modal(modal, {});

function checkPopupCookie(): void {
    // Function to get a cookie's value by name
    function getCookie(name: string): string | null {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i=0;i < ca.length;i++) {
          let c = ca[i];
          while (c?.charAt(0)==' ') c = c?.substring(1,c?.length);
          if (c?.indexOf(nameEQ) == 0) return c?.substring(nameEQ.length,c?.length);
      }
      return null;
    }
  
    function showPopup(): void {
      setTimeout(() => {
        uploadModal.show();
      }, 4000)
    }
  
    function setCookie(name: string, value: string, days: number): void {
      let expires = "";
      if (days) {
          let date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
  
    const popupShown = getCookie('popupHasBeenShown');
  
    if (!popupShown) {
      showPopup();
      setCookie('popupHasBeenShown', 'true', 30); // Set the cookie to expire in 30 days
    }
  }
  
  document.addEventListener('DOMContentLoaded', () => {
    checkPopupCookie();
  });
