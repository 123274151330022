import '../modules/drawer.ts'
import '../modules/search.ts'
import '../modules/toast.ts'
import '../modules/modal.ts'
import '../modules/hs_forms.js'
// TODO: move carousel import only to templates that use it
import '../modules/carousel.ts'
import { Input, Carousel, Select, Dropdown, Ripple, Collapse, SmoothScroll, PerfectScrollbar, Offcanvas, Modal, initTE } from 'tw-elements';
import {
  setupDeleteButtonListeners,
  setupAddToCartButtonListeners,
  setupEstimateShippingButtonListeners,
} from '../modules/cart';
import { setupHoverCollectionEvent } from '../modules/menu';

initTE({ Input, Carousel, Select, Dropdown, Ripple, Collapse, SmoothScroll, PerfectScrollbar, Offcanvas, Modal });
setupDeleteButtonListeners();
setupAddToCartButtonListeners();
setupHoverCollectionEvent();
setupEstimateShippingButtonListeners();
