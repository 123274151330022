export const handleHover = (event: MouseEvent): void => {
  const target = event.currentTarget as HTMLElement;

  if (target.getAttribute('aria-expanded') === 'false' || !target.classList.contains('open')) {
    const hoverTimer = setTimeout(() => {
      // Create a new click event with a custom type
      const clickEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      }) as MouseEvent & { preventRedirect?: boolean };

      // Assign the custom property
      clickEvent.preventRedirect = true;

      target.dispatchEvent(clickEvent);
      target.classList.add('open');
    }, 150);

    target.addEventListener('mouseleave', () => {
      target.classList.remove('open');
      clearTimeout(hoverTimer);
    }, { once: true });
  }
};

export function setupHoverCollectionEvent(): void {
  function handleHoverLinkCollections(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const url = target.getAttribute('href');

    const imageUrl = target.dataset.navMedia;
    const imageEl = document.querySelector('.collection-nav-gallery') as HTMLImageElement;
    const collectionsGallery = document.getElementById('collections-container') as HTMLElement;

    if (imageUrl && imageEl && url) {
      collectionsGallery.classList.add('flex');
      collectionsGallery.classList.remove('invisible');
      imageEl.parentElement?.setAttribute('href', url);
      imageEl.src = imageUrl;
      imageEl.removeAttribute('srcset');
    } else {
      collectionsGallery.classList.add('invisible');
    }
  }

  function handleHoverLinkAreas(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const url = target.getAttribute('href');

    const imageUrl = target.dataset.navMedia;
    const imageEl = document.querySelector('.areas-nav-gallery') as HTMLImageElement;
    const areasGallery = document.getElementById('areas-container') as HTMLElement;

    if (imageUrl && imageEl && url) {
      areasGallery.classList.add('flex');
      areasGallery.classList.remove('invisible');
      imageEl.parentElement?.setAttribute('href', url);
      imageEl.src = imageUrl;
      imageEl.removeAttribute('srcset');
    } else {
      areasGallery.classList.add('invisible');
    }
  }

  function handleHoverLinkNews(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const url = target.getAttribute('href');

    const imageUrl = target.dataset.navMedia;
    const imageEl = document.querySelector('.news-nav-gallery') as HTMLImageElement;
    const collectionsGallery = document.getElementById('news-container') as HTMLElement;

    if (imageUrl && imageEl && url && target.id !== 'shop-all') {
      collectionsGallery.classList.add('flex');
      collectionsGallery.classList.remove('invisible');
      imageEl.parentElement?.setAttribute('href', url);
      imageEl.src = imageUrl;
      imageEl.removeAttribute('srcset');
    } else {
      collectionsGallery.classList.add('invisible');
    }
  }

  document.addEventListener('DOMContentLoaded', () => {
    const megaMenuParents = document.getElementsByClassName('js-mega-menu-parent');

    for (let i = 0; i < megaMenuParents.length; i++) {
      const megaMenuParent = megaMenuParents[i] as HTMLElement;
      megaMenuParent.addEventListener('click', (event) => {
        const target = event.currentTarget as HTMLElement;
        if (target.id === 'shop') {
          const areasResetLink = document.getElementById('link-areas') as HTMLElement;
          if (areasResetLink) {
            // Create a new mouseover event
            const mouseOverEvent = new MouseEvent('mouseover', {
              bubbles: true,
              cancelable: true,
              view: window
            });
            // Dispatch the mouseover event on the target element
            areasResetLink.dispatchEvent(mouseOverEvent);
          }
        }
      });
    }
    

    const elements = document.getElementsByClassName('js-prevent-close');

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
    
      element.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation(); // Prevents the event from propagating to parent elements
      }, true);
    }

    const collectionsContainer = document.getElementById('collections');
    if (collectionsContainer) {
      const collections = collectionsContainer.querySelectorAll('a');
      collections.forEach((collection) => {
        if (collection instanceof HTMLElement) {
          collection.addEventListener('mouseover', handleHoverLinkCollections);
        }
      });
    }

    const areasContainer = document.getElementById('areas');
    if (areasContainer) {
      const collections = areasContainer.querySelectorAll('a');
      collections.forEach((collection) => {
        if (collection instanceof HTMLElement) {
          collection.addEventListener('mouseover', handleHoverLinkAreas);
        }
      });
    }

    const newsContainer = document.getElementById('news');
    if (newsContainer) {
      const news = newsContainer.querySelectorAll('a');
      news.forEach((newsLink) => {
        if (newsLink instanceof HTMLElement) {
          newsLink.addEventListener('mouseover', handleHoverLinkNews);
        }
      });
    }
  });
}


document.addEventListener('DOMContentLoaded', function () {
  const parentLinks = document.querySelectorAll<HTMLAnchorElement>('.parent-link');
  const childLinkContainers = document.querySelectorAll<HTMLElement>('.child-links');

  parentLinks.forEach(link => {
      link.addEventListener('mouseover', function (event: Event) {
          event.preventDefault();
          const target = event.currentTarget as HTMLAnchorElement;
          const href = target.getAttribute('href');
          if (href) {
              const targetId = href.substring(1);
              const areasGallery = document.getElementById('areas-container') as HTMLElement;
              const collectionsGallery = document.getElementById('collections-container') as HTMLElement;
              const quickShipGallery = document.getElementById('quick-ship-container') as HTMLElement;

              if (targetId === 'areas') {
                areasGallery.classList.add('flex');
                areasGallery.classList.remove('hidden');
              } else {
                areasGallery.classList.remove('flex');
                areasGallery.classList.add('hidden');
              }
              
              if (targetId === 'collections') {
                collectionsGallery.classList.add('flex');
                collectionsGallery.classList.remove('hidden');
              } else {
                collectionsGallery.classList.remove('flex');
                collectionsGallery.classList.add('hidden');
              }

              if (targetId === 'collections/quick-ship') {
                quickShipGallery.classList.add('flex');
                quickShipGallery.classList.remove('hidden');
              } else {
                quickShipGallery.classList.remove('flex');
                quickShipGallery.classList.add('hidden');
              }

              // Hide all child link containers and update aria-expanded attribute
              childLinkContainers.forEach(child => {
                  child.classList.add('hidden');
                  child.classList.remove('grid');
              });
              parentLinks.forEach(parent => {
                  parent.setAttribute('aria-expanded', 'false');
                  parent.classList.add('!text-neutral-400')
              });

              // Show the corresponding child link container and update aria-expanded attribute
              const childLinkContainer = document.getElementById(targetId);
              if (childLinkContainer) {
                  childLinkContainer.classList.remove('hidden');
                  if (targetId === 'collections') {
                    childLinkContainer.classList.add('grid');
                  } else {
                    childLinkContainer.classList.add('flex');
                  }
   
                  target.setAttribute('aria-expanded', 'true');
                  target.classList.remove('!text-neutral-400')
              }
          }
      });
  });
});